import { JsonProperty, Serializable } from "@anna-money/anna-web-lib/serializerOld";
import { CompanyProduct } from "services/companyProducts/companyProductsTypes";

export enum PricingPlan {
  JustRegister = "just_register",
  FullFormation = "full_formation", // deprecated
  FullFormation19 = "full_formation_19",
  BusinessSecretary = "business_secretary",
  AllInOne = "all_on_one",
  // AU
  EasyCompany = "easy_company",
  AnnaOne = "anna_one_yearly",
}

export type PricingPlanGb =
  | PricingPlan.JustRegister
  | PricingPlan.FullFormation19
  | PricingPlan.FullFormation
  | PricingPlan.BusinessSecretary
  | PricingPlan.AllInOne;

export type PricingPlanAu = PricingPlan.EasyCompany | PricingPlan.AnnaOne;

export type PricingPlanProducts = Record<PricingPlan, CompanyProduct[]>;

@Serializable()
export class PricingPlansProductsData {
  constructor(@JsonProperty({ type: Object }) readonly products: PricingPlanProducts) {}
}
