import { AnnaError, type Nullable } from "@anna-money/anna-web-lib/utils";

export const ensured = <T>(value: Nullable<T>): T => {
  if (value === null || typeof value === "undefined") {
    throw new AnnaError("Unexpected nullable value");
  }

  return value;
};

export const notNull = <T>(value: T | null): value is T => {
  return value !== null;
};
