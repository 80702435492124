import { JsonProperty, Serializable } from "@anna-money/anna-web-lib/serializerOld";

export enum IncorporationStatus {
  New = "NEW",
  FailedToSubmit = "FAILED_TO_SUBMIT",
  InProgress = "IN_PROGRESS",
  DownloadingDocuments = "DOWNLOADING_DOCUMENTS",
  DownloadFailed = "DOWNLOAD_FAILED",
  CreatingInvitation = "CREATING_INVITATION",
  InvitationFailed = "INVITATION_FAILED",
  SendingInvitationEmail = "SENDING_INVITATION_EMAIL",
  Success = "SUCCESS",
  Failed = "FAILED",
  FailedNeedManualResponse = "FAILED_NEED_MANUAL_RESPONSE",
  CreatingAccount = "CREATING_ACCOUNT",
  CreatingAccountFailed = "CREATING_ACCOUNT_FAILED",
  Obsolete = "OBSOLETE",
  Rejected = "REJECTED",
  SameDayFormationFailed = "SAME_DAY_FORMATION_FAILED",
}

@Serializable()
export class IncorporationData {
  constructor(
    @JsonProperty() readonly id: string,
    @JsonProperty() readonly companyId: string,
    @JsonProperty() readonly created: string,
    @JsonProperty({ type: IncorporationStatus }) readonly status: IncorporationStatus,
    @JsonProperty({ type: String, required: false }) readonly submittedAt: Nullable<string>,
    @JsonProperty({ type: String, required: false }) readonly finishedAt: Nullable<string>,
  ) {}
}

@Serializable()
export class IncorporationListData {
  constructor(@JsonProperty({ type: IncorporationData, isArray: true }) readonly incorporations: IncorporationData[]) {}
}

@Serializable()
export class IncorporationCreateData {
  constructor(@JsonProperty() readonly companyId: string) {}
}
