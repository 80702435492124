import { type ApiClient } from "@anna-money/anna-web-lib/api";

import { NationalitiesData } from "./nationalitiesTypes";

export class NationalitiesClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  async getNationalities(): Promise<NationalitiesData> {
    const response = await this._apiClient.get("api/formations/nationalities");

    return response.getData(NationalitiesData);
  }
}
