import { ApiClient, FileShareClient } from "@anna-money/anna-web-lib/api";
import { type NavigateFunction } from "react-router-dom";
import { Memoize } from "typescript-memoize";
import { DataStorage } from "services/storage/dataStorage";
import { AuthClient } from "./auth/authClient";
import { AuthStore } from "./auth/authStore";
import { AuthenticationHandler } from "./auth/authenticationApiHandler";
import { Authenticator } from "./auth/authenticator";
import { Config } from "./config/config";
import { apiSerializer, apiSerializerNew } from "./helpers/serialization";
import { AddressClient } from "./services/address/addressClient";
import { AddressSearchClient } from "./services/addressSearch/addressSearchClient";
import { AddressSearchStore } from "./services/addressSearch/addressSearchStore";
import { AddressVerificationClient } from "./services/addressVerification/addressVerificationClient";
import { AddressVerificationStore } from "./services/addressVerification/addressVerificationStore";
import { AnalyticsManager } from "./services/analytics/analyticsManager";
import { AttributesClient } from "./services/attributes/attributesClient";
import { BillingClient } from "./services/billing/billingClient";
import { BillingControllerStore } from "./services/billingController/billingControllerStore";
import { BusinessActivitiesClient } from "./services/businessActivities/businessActivitiesClient";
import { BusinessActivitiesStore } from "./services/businessActivities/businessActivitiesStore";
import { CardDetailsClient } from "./services/cardDetails/cardDetailsClient";
import { CardDetailsStore } from "./services/cardDetails/cardDetailsStore";
import { CompanyClient } from "./services/company/companyClient";
import { CompanyStore } from "./services/company/companyStore";
import { CompanyProductsClient } from "./services/companyProducts/companyProductsClient";
import { CompanyProductsStore } from "./services/companyProducts/companyProductsStore";
import { ComplianceClient } from "./services/compliance/complianceClient";
import { ComplianceStore } from "./services/compliance/complianceStore";
import { ConfirmStepStore } from "./services/confirmStep/confirmStepStore";
import { ConsentClient } from "./services/consent/consentClient";
import { ConsentStore } from "./services/consent/consentStore";
import { DeepLinkClient } from "./services/deepLink/deepLinkClient";
import { DirectorAddressStore } from "./services/director/directorAddressStore";
import { DirectorAttributesStore } from "./services/director/directorAttributesStore";
import { DirectorClient } from "./services/director/directorClient";
import { DirectorStore } from "./services/director/directorStore";
import { DirectorIdentityWidgetStore } from "./services/directorIdentity/directorIdentityWidgetStore";
import { DirectorPoaStore } from "./services/directorIdentity/directorPoaStore";
import { DirectorPositionStore } from "./services/directorPosition/directorPositionStore";
import { FeatureStore } from "./services/feature/featureStore";
import { FormationExpressStore } from "./services/formationExpress/formationExpressStore";
import { IncorporationClient } from "./services/incorporation/incorporationClient";
import { IncorporationStore } from "./services/incorporation/incorporationStore";
import { IssuesClient } from "./services/issues/issuesClient";
import { IssuesStore } from "./services/issues/issuesStore";
import { LeadClient } from "./services/lead/leadClient";
import { NameCheckerClient } from "./services/nameChecker/nameCheckerClient";
import { NameCheckerStore } from "./services/nameChecker/nameCheckerStore";
import { NationalitiesClient } from "./services/nationalities/nationalitiesClient";
import { NationalitiesStore } from "./services/nationalities/nationalitiesStore";
import { PaymentMethodsStore } from "./services/paymentMethods/paymentMethodsStore";
import { ProfileStore } from "./services/profile/profileStore";
import { BasketQuestionsStore } from "./services/questions/gb/basketQuestionsStore";
import { SentryHost } from "./services/sentry/sentryHost";
import { CookieStorage } from "./services/storage/cookieStorage";
import { StripeFormStore } from "./services/stripeForm/stripeFormStore";
import { StripeIntegrationClient } from "./services/stripeIntegration/stripeIntegrationClient";
import { SumsubApplicationStore } from "./services/sumsub/sumsubApplicationStore";
import { SumsubClient } from "./services/sumsub/sumsubClient";
import type { UserClient } from "./services/user/userClient";
import { UserStore } from "./services/user/userStore";
import { type UpdateFormCallback } from "./types/form";

export class BaseServices {
  constructor(config: Config) {
    this.config = config;
  }

  readonly config: Config;

  @Memoize()
  get sentryHost(): SentryHost {
    return new SentryHost();
  }

  @Memoize()
  get dataStorage(): DataStorage {
    return new DataStorage();
  }

  @Memoize()
  get authClient(): AuthClient {
    return new AuthClient(this.config.annaAuthUrl);
  }

  @Memoize()
  get authenticator(): Authenticator {
    return new Authenticator(this.config, this.authClient, this.dataStorage);
  }

  @Memoize()
  get authStore(): AuthStore {
    return new AuthStore(this.authenticator);
  }

  @Memoize()
  get analyticsManager(): AnalyticsManager {
    return new AnalyticsManager(this.config.annaApiUrl, this.config.webticsTrackerUrl, this.config.appVersion);
  }
}

export abstract class Services {
  protected constructor(
    protected readonly _baseServices: BaseServices,
    protected readonly _authenticationHandler: AuthenticationHandler,
    protected readonly _apiClient: ApiClient,
    protected readonly _userClient: UserClient,
    protected readonly _userStore: UserStore,
    protected readonly _profileStore: ProfileStore,
    protected readonly _featureStore: FeatureStore,
    protected readonly _navigate: NavigateFunction,
  ) {}

  @Memoize()
  get config(): Config {
    return this._baseServices.config;
  }

  @Memoize()
  private get authenticationHandler(): AuthenticationHandler {
    return this._authenticationHandler;
  }

  @Memoize()
  get apiClient(): ApiClient {
    return this._apiClient;
  }

  @Memoize()
  get analyticsManager(): AnalyticsManager {
    return this._baseServices.analyticsManager;
  }

  @Memoize()
  get navidate(): NavigateFunction {
    return this._navigate;
  }

  @Memoize()
  get profileStore(): ProfileStore {
    return this._profileStore;
  }

  @Memoize()
  get featureStore(): FeatureStore {
    return this._featureStore;
  }

  @Memoize()
  get userStore(): UserStore {
    return this._userStore;
  }

  @Memoize()
  get sentryHost(): SentryHost {
    return new SentryHost();
  }

  @Memoize()
  get authenticator(): Authenticator {
    return new Authenticator(this.config, new AuthClient(this.config.annaAuthUrl), this.dataStorage);
  }

  @Memoize()
  get authStore(): AuthStore {
    return new AuthStore(this.authenticator);
  }

  @Memoize()
  get fileShareClient(): FileShareClient {
    const mediaApiClient = new ApiClient({
      apiUrl: this.config.annaMediaApiUrl,
      apiSerializerOld: apiSerializer,
      apiSerializerNew: apiSerializerNew,
      handlers: [this.authenticationHandler],
    });

    return new FileShareClient(this.apiClient, mediaApiClient);
  }

  @Memoize()
  get dataStorage(): DataStorage {
    return new DataStorage();
  }

  @Memoize()
  get cookieStorage(): CookieStorage {
    return new CookieStorage();
  }

  @Memoize()
  get addressClient(): AddressClient {
    return new AddressClient(this.apiClient);
  }

  @Memoize()
  get addressSearchStore(): AddressSearchStore {
    return new AddressSearchStore(new AddressSearchClient(this.apiClient));
  }

  @Memoize()
  get addressVerificationStore(): AddressVerificationStore {
    return new AddressVerificationStore(
      this.companyStore,
      this.directorStore,
      this.directorAddressStore,
      new AddressVerificationClient(this.apiClient),
    );
  }

  @Memoize()
  get businessActivitiesClient(): BusinessActivitiesClient {
    return new BusinessActivitiesClient(this.apiClient);
  }

  @Memoize()
  get businessActivitiesStore(): BusinessActivitiesStore {
    return new BusinessActivitiesStore(this.businessActivitiesClient);
  }

  @Memoize()
  get cardDetailsStore(): CardDetailsStore {
    return new CardDetailsStore(new CardDetailsClient(this.apiClient), this.analyticsManager);
  }

  @Memoize()
  get companyClient(): CompanyClient {
    return new CompanyClient(this.apiClient);
  }

  @Memoize()
  get companyStore(): CompanyStore {
    return new CompanyStore(this.companyClient, this.featureStore);
  }

  @Memoize()
  get consentStore(): ConsentStore {
    return new ConsentStore(new ConsentClient(this.apiClient));
  }

  @Memoize()
  get issuesStore(): IssuesStore {
    return new IssuesStore(new IssuesClient(this.apiClient));
  }

  @Memoize()
  get companyProductsClient(): CompanyProductsClient {
    return new CompanyProductsClient(this.apiClient);
  }

  @Memoize()
  get companyProductsStore(): CompanyProductsStore {
    return new CompanyProductsStore(this.companyStore, this.companyProductsClient);
  }

  @Memoize()
  get complianceClient(): ComplianceClient {
    return new ComplianceClient(this.apiClient);
  }

  @Memoize()
  get deepLinkClient(): DeepLinkClient {
    return new DeepLinkClient(this.apiClient);
  }

  @Memoize()
  get directorAttributesStore(): DirectorAttributesStore {
    return new DirectorAttributesStore(new AttributesClient(this.apiClient));
  }

  @Memoize()
  get directorAddressStore(): DirectorAddressStore {
    return new DirectorAddressStore(this.addressClient);
  }

  @Memoize()
  get directorPositionsStore(): DirectorPositionStore {
    return new DirectorPositionStore(this.dataStorage);
  }

  @Memoize()
  get directorStore(): DirectorStore {
    return new DirectorStore(
      new DirectorClient(this.apiClient),
      this.companyStore,
      this.directorAttributesStore,
      this.directorAddressStore,
      this.config,
    );
  }

  @Memoize()
  get incorporationStore(): IncorporationStore {
    return new IncorporationStore(new IncorporationClient(this.apiClient), this.companyStore);
  }

  @Memoize()
  get leadClient(): LeadClient {
    return new LeadClient(this.apiClient);
  }

  @Memoize()
  get nameCheckerStore(): NameCheckerStore {
    return new NameCheckerStore(new NameCheckerClient(this.apiClient));
  }

  @Memoize()
  get nationalitiesStore(): NationalitiesStore {
    return new NationalitiesStore(new NationalitiesClient(this.apiClient));
  }

  @Memoize()
  get paymentMethodsStore(): PaymentMethodsStore {
    return new PaymentMethodsStore(this.stripeIntegrationClient);
  }

  @Memoize()
  get basketQuestionsStore(): BasketQuestionsStore {
    return new BasketQuestionsStore(this.issuesStore);
  }

  @Memoize()
  get stripeIntegrationClient(): StripeIntegrationClient {
    return new StripeIntegrationClient(this.apiClient);
  }

  @Memoize()
  get sumsubClient(): SumsubClient {
    return new SumsubClient(this.apiClient);
  }

  @Memoize()
  get sumsubApplicationStore(): SumsubApplicationStore {
    return new SumsubApplicationStore(this.sumsubClient);
  }

  @Memoize()
  get formationExpressStore(): FormationExpressStore {
    return new FormationExpressStore(this.companyProductsClient, this.companyProductsStore);
  }

  createBillingControllerStore = (): BillingControllerStore =>
    new BillingControllerStore(new BillingClient(this.apiClient));

  createStripeFormStore = (): StripeFormStore =>
    new StripeFormStore(this.stripeIntegrationClient, this.analyticsManager, this.config);

  createComplianceStore = (): ComplianceStore =>
    new ComplianceStore(this.sumsubApplicationStore, this.complianceClient, this.addressVerificationStore);

  createDirectorIdentityWidgetStore = (): DirectorIdentityWidgetStore =>
    new DirectorIdentityWidgetStore(this.sumsubClient, this.analyticsManager);

  createDirectorPoaStore = (): DirectorPoaStore =>
    new DirectorPoaStore(this.companyStore, this.fileShareClient, this.analyticsManager);

  createConfirmStepStore = (callback: UpdateFormCallback): ConfirmStepStore =>
    new ConfirmStepStore(callback, this.companyProductsStore, this.formationExpressStore);
}
