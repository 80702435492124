import { JsonProperty, Serializable } from "@anna-money/anna-web-lib/serializerOld";

@Serializable()
export class BusinessActivityData {
  constructor(
    // id always present in GB and not present in AU, make it required after backend refactoring
    @JsonProperty({ required: false, type: String }) readonly id: Nullable<string>,
    @JsonProperty() readonly code: string,
    @JsonProperty() readonly description: string,
  ) {}
}

@Serializable()
export class BusinessActivitiesData {
  constructor(
    @JsonProperty({ type: BusinessActivityData, isArray: true }) readonly businessCodes: BusinessActivityData[],
  ) {}
}
