import { Cookies } from "react-cookie";

export enum CookieName {
  BusinessSecretaryExperiment = "formations_business_secretary_bundle",
  PricingPlanExperiment = "formations_pp_basic_19_to_50_feb2025",
}

export class CookieStorage {
  _storage = new Cookies(null);

  get(key: CookieName): string | null;
  get(key: CookieName, type: StringConstructor): string | null;
  get(key: CookieName, type: NumberConstructor): number | null;
  get(key: CookieName, type?: StringConstructor | NumberConstructor): string | number | null {
    const item = this._storage.get<string>(key);

    if (!item) {
      return null;
    }

    if (!type || type === String) {
      return item;
    }

    return JSON.parse(item);
  }
}
