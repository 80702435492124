import type { ParticularsFormValues } from "helpers/forms/particularsTypes";
import { CompanyStore } from "services/company/companyStore";
import type { CompanyProductsStore } from "services/companyProducts/companyProductsStore";
import { CompanyProduct } from "services/companyProducts/companyProductsTypes";
import { DirectorStore } from "services/director/directorStore";
import { ParticularsEntity } from "services/questions/gb/particularsConstants";
import { FormError } from "types/errors";

export class ParticularsStepStore {
  private readonly _directorStore: DirectorStore;
  private readonly _companyStore: CompanyStore;
  private readonly _companyProductStore: CompanyProductsStore;

  constructor(directorStore: DirectorStore, companyProductStore: CompanyProductsStore, companyStore: CompanyStore) {
    this._directorStore = directorStore;
    this._companyProductStore = companyProductStore;
    this._companyStore = companyStore;
  }

  async updateMainParticulars(formValues: ParticularsFormValues, entity: ParticularsEntity): Promise<void> {
    const { totalShares, totalCapital, directors: directorShares } = formValues || {};

    if (!totalShares || !totalCapital || !directorShares) {
      throw new FormError("Something went wrong");
    }
    if (entity === ParticularsEntity.Particulars) {
      await this.updateShares(formValues);
      return;
    }

    if (entity === ParticularsEntity.SharesManagement) {
      await this._updateSharesManagement(formValues);
    }
  }

  private async _updateSharesManagement(formValues: ParticularsFormValues): Promise<void> {
    formValues.sharesManagement
      ? await this._companyProductStore.addProduct(CompanyProduct.SharesManagement)
      : await this._companyProductStore.deleteProduct(CompanyProduct.SharesManagement);
  }

  async updateShares(formValues: ParticularsFormValues): Promise<void> {
    const { totalShares, totalCapital, directors: directorShares } = formValues || {};

    if (!totalShares || !totalCapital || !directorShares) {
      throw new FormError("Something went wrong");
    }

    await this._companyStore.updateShares(totalShares, totalCapital);

    for (const director of this._directorStore.directors) {
      const value = directorShares[director.id];
      await this._directorStore.updateShares(director.id, value ? parseInt(value) : undefined);
    }
  }
}
