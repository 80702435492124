import { JsonProperty, Serializable } from "@anna-money/anna-web-lib/serializerOld";

export enum SumsubCheckFlowName {
  ONE_CLICK_LEVEL = "1click_level",
}

@Serializable()
export class SumsubTokenRequest {
  constructor(@JsonProperty({ required: false, type: SumsubCheckFlowName }) readonly flowType: SumsubCheckFlowName) {}
}

@Serializable()
export class SumsubTokenResponse {
  constructor(@JsonProperty({ required: true }) readonly token: string) {}
}

@Serializable()
export class SumsubApplication {
  constructor(
    @JsonProperty({ required: false, type: String }) readonly firstName: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly lastName: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly dob: Nullable<string>,
    @JsonProperty({ type: Boolean }) readonly isPassedVerifications: boolean,
  ) {}
}

@Serializable()
export class SumsubCreateApplicationData {
  constructor(@JsonProperty() readonly applicationId: string) {}
}
