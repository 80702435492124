import { makeAutoObservable } from "mobx";

import { companyFormToCompanyUpdateData } from "helpers/forms/incorporation/company";
import type { CompanyFormValues } from "helpers/forms/incorporation/companyTypes";
import { CompanyStore } from "services/company/companyStore";
import { CompanyProductsStore } from "services/companyProducts/companyProductsStore";
import { CompanyProduct, type DefaultCompanyProductDetailsData } from "services/companyProducts/companyProductsTypes";
import { PricingPlanStore } from "services/pricingPlan/gb/pricingPlanStore";
import { type PricingPlanGb } from "services/pricingPlan/pricingPlanTypes";
import { FormError } from "types/errors";

export enum InitStepEntity {
  Name = "Name",
  PricingPlan = "PricingPlan",
  SameDayRegistration = "SameDayRegistration",
}

export class InitStepStore {
  constructor(
    private readonly _companyStore: CompanyStore,
    private readonly _companyProductsStore: CompanyProductsStore,
    private readonly _pricingPlanStore: PricingPlanStore,
  ) {
    makeAutoObservable(this);
  }

  async updateEntity(data: CompanyFormValues, entity: InitStepEntity): Promise<void> {
    switch (entity) {
      case InitStepEntity.Name:
        if (!this._companyStore.company) {
          await this._companyStore.createCompany(data.name, data.namePostfix);
        } else {
          await this._companyStore.updateName(data.name, data.namePostfix);
        }
        break;
      case InitStepEntity.PricingPlan:
        await this._updatePricingPlan(data.pricingPlan);
        break;
      case InitStepEntity.SameDayRegistration:
        await this._updateProduct(CompanyProduct.SameDayRegistration, Boolean(data.sameDayRegistration));
        break;
      default:
        await this._companyStore.updateCompany(companyFormToCompanyUpdateData(data));
    }
  }

  private async _updateProduct(
    product: CompanyProduct,
    toggle: boolean,
    details?: DefaultCompanyProductDetailsData,
  ): Promise<void> {
    toggle
      ? await this._companyProductsStore.addProduct(product, details)
      : await this._companyProductsStore.deleteProduct(product);
  }

  private async _updatePricingPlan(pricingPlan?: PricingPlanGb): Promise<void> {
    if (!pricingPlan) {
      throw new FormError("No pricing plan selected");
    }

    await this._pricingPlanStore.updatePricingPlan(pricingPlan);
  }
}
