import { type ApiClient } from "@anna-money/anna-web-lib/api";
import { ConsentData, type ConsentType } from "./consentTypes";

export class ConsentClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  async getConsents(): Promise<ConsentType[]> {
    const response = await this._apiClient.get("api/formations/consent");
    const consentData = response.getData(ConsentData);
    return consentData.items;
  }

  async acceptConsents(data: ConsentData): Promise<ConsentType[]> {
    const response = await this._apiClient.post("api/formations/consent", data);
    const consentData = response.getData(ConsentData);
    return consentData.items;
  }
}
