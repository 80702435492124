import type { NavigateFunction } from "react-router-dom";
import type { Config } from "config/config";
import type { AnalyticsManager } from "services/analytics/analyticsManager";
import type { CompanyClient } from "services/company/companyClient";
import { type CompanyData, CompanyNamePostfix, CompanyPricingPlanUpdateData } from "services/company/companyTypes";
import type { LeadClient } from "services/lead/leadClient";
import { PricingPlan } from "services/pricingPlan/pricingPlanTypes";
import { SignUpPageStoreBase } from "services/sighUpPage/signUpPageStoreBase";
import type { UserStore } from "services/user/userStore";

const pricingPlanUrlParam = "pricing_plan";

export class SignUpPageStore extends SignUpPageStoreBase {
  protected _defaultPostfix = CompanyNamePostfix.PtyLtdV1;

  constructor(
    protected readonly _companyClient: CompanyClient,
    protected readonly _leadClient: LeadClient,
    protected readonly _analyticsManager: AnalyticsManager,
    protected readonly _userStore: UserStore,
    protected readonly _navigate: NavigateFunction,
    protected readonly _config: Config,
  ) {
    super(_companyClient, _leadClient, _analyticsManager, _userStore, _navigate, _config);
  }

  protected async _proceedSignUp(): Promise<undefined | CompanyData> {
    const company = await super._proceedSignUp();

    if (company) {
      await this._savePricingPlan(this._extractSearchParams(), company);
    }

    return company;
  }

  private async _savePricingPlan(searchParams: URLSearchParams, company: CompanyData): Promise<void> {
    const pricingPlan = searchParams.get(pricingPlanUrlParam); // 'basic' or 'anna_one'

    if (!pricingPlan) {
      return;
    }

    switch (pricingPlan) {
      case "basic":
        await this._companyClient.updateCompany(company.id, new CompanyPricingPlanUpdateData(PricingPlan.EasyCompany));
        break;
      case "anna_one":
        await this._companyClient.updateCompany(company.id, new CompanyPricingPlanUpdateData(PricingPlan.AnnaOne));
        break;
    }
  }
}
