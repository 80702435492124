export const replaceSpacesToNbsp = (str: string): string => {
  return str.replace(/\s/g, "\u00A0");
};

const FIRST_LETTER_REGEXP = /(^|\W)(\w)/g;

export const upperCaseFirstLetter = (value: string): null | string => {
  if (!value) {
    return null;
  }

  return value.replace(FIRST_LETTER_REGEXP, (firstLetter) => firstLetter.toUpperCase());
};
