import { JsonProperty, Serializable } from "@anna-money/anna-web-lib/serializerOld";

@Serializable()
export class QrCodeData {
  constructor(
    @JsonProperty() readonly data: string,
    @JsonProperty({ name: "mediaType" }) readonly mediaType: string,
    @JsonProperty() readonly encoding: string,
  ) {}
}

@Serializable()
export class MagicLinkData {
  constructor(@JsonProperty() readonly magicLink: string) {}
}
