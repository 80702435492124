import { useMemo } from "react";
import { useServices } from "appServices";
import { ConsentData, ConsentType } from "services/consent/consentTypes";
import { type ConsentFormValues } from "./consentTypes";

const consentDataToConsentForm = (consents: ConsentType[]): ConsentFormValues => {
  return {
    confirmationStatement: consents.includes(ConsentType.ConfirmationStatement),
    actAsShareholder: consents.includes(ConsentType.ActAsShareholder),
    actAsDirector: consents.includes(ConsentType.ActAsDirector),
    annaTerms: consents.includes(ConsentType.AnnaTerms),
    customerAgreement: consents.includes(ConsentType.CustomerAgreement),
    privacyPolicy: consents.includes(ConsentType.PrivacyPolicy),
    complianceStatement: consents.includes(ConsentType.AuComplianceStatement),
  };
};

export const consentFormToConsentData = (consentForm: ConsentFormValues): ConsentData => {
  const consents: ConsentType[] = [];
  if (consentForm.confirmationStatement) {
    consents.push(ConsentType.ConfirmationStatement);
  }
  if (consentForm.actAsDirector) {
    consents.push(ConsentType.ActAsDirector);
  }
  if (consentForm.actAsShareholder) {
    consents.push(ConsentType.ActAsShareholder);
  }
  if (consentForm.annaTerms) {
    consents.push(ConsentType.AnnaTerms);
  }
  if (consentForm.customerAgreement) {
    consents.push(ConsentType.CustomerAgreement);
  }
  if (consentForm.privacyPolicy) {
    consents.push(ConsentType.PrivacyPolicy);
  }
  if (consentForm.complianceStatement) {
    consents.push(ConsentType.AuComplianceStatement);
  }

  return new ConsentData(consents);
};

export const useConsentFormInitialValue = (): ConsentFormValues => {
  const { consentStore } = useServices();

  return useMemo(() => consentDataToConsentForm(consentStore.consentsData), []);
};
