import { type FileShareClient } from "@anna-money/anna-web-lib/api";
import { makeAutoObservable } from "mobx";
import { type AnalyticsManager } from "services/analytics/analyticsManager";
import { type CompanyStore } from "services/company/companyStore";

export class DirectorPoaStore {
  private _poaFile?: File;

  constructor(
    private readonly _companyStore: CompanyStore,
    private readonly _fileShareClient: FileShareClient,
    private readonly _analyticsManager: AnalyticsManager,
  ) {
    makeAutoObservable(this);
  }

  get poaFile(): File | undefined {
    return this._poaFile;
  }

  set poaFile(value: File | undefined) {
    this._poaFile = value;
  }

  async onChange(file: File): Promise<void> {
    if (!file) {
      this._analyticsManager.event("poa-error-no-file");
      return;
    }

    try {
      const completeResponse = await this._fileShareClient.uploadFile(file);
      await this._companyStore.updatePoa(completeResponse.id);

      this.poaFile = file;
      this._analyticsManager.event("address_check_submission", { file: completeResponse.id });
    } catch (e) {
      console.error(e);
      this._analyticsManager.event("fail-poa");
    }
  }
}
