import { makeAutoObservable } from "mobx";

import { companyFormToCompanyUpdateData } from "helpers/forms/incorporation/company";
import type { CompanyFormValues } from "helpers/forms/incorporation/companyTypes";
import { CompanyStore } from "services/company/companyStore";
import { CompanyAddressStore } from "services/companyAddress/gb/companyAddressStore";
import { CompanyProductsStore } from "services/companyProducts/companyProductsStore";
import { CompanyProduct, type DefaultCompanyProductDetailsData } from "services/companyProducts/companyProductsTypes";
import type { DirectorStepFormValues } from "helpers/forms/directorTypes";

export enum CompanyStepEntity {
  Address = "Address",
  Country = "Country",
  AnnaTaxes = "AnnaTaxes",
  VatRegistration = "VatRegistration",
  ConfirmationStatement = "ConfirmationStatement",
  Check = "Check",
  Management = "Management",
  PayeRegistration = "PayeRegistration",
  CancellationCover = "CancellationCover",
}

export class CompanyStepStore {
  constructor(
    private readonly _companyStore: CompanyStore,
    private readonly _companyAddressStore: CompanyAddressStore,
    private readonly _companyProductsStore: CompanyProductsStore,
  ) {
    makeAutoObservable(this);
  }

  private async _updateCancellationCover(formValues: DirectorStepFormValues): Promise<void> {
    formValues.cancellationCover
      ? await this._companyProductsStore.addProduct(CompanyProduct.CancellationCover)
      : await this._companyProductsStore.deleteProduct(CompanyProduct.CancellationCover);
  }

  private async _updatePayeRegistration(formValues: DirectorStepFormValues): Promise<void> {
    formValues.payeRegistration
      ? await this._companyProductsStore.addProduct(CompanyProduct.PayeRegistration)
      : await this._companyProductsStore.deleteProduct(CompanyProduct.PayeRegistration);
  }

  private async _updateDirectoryManagement(formValues: DirectorStepFormValues): Promise<void> {
    formValues.managementSecretary
      ? await this._companyProductsStore.addProduct(CompanyProduct.DirectoryManagement)
      : await this._companyProductsStore.deleteProduct(CompanyProduct.DirectoryManagement);
  }

  async updateEntity(data: CompanyFormValues, entity: CompanyStepEntity): Promise<void> {
    switch (entity) {
      case CompanyStepEntity.Address:
        await this._companyAddressStore.updateAddress(
          data.useVirtualOffice
            ? { virtualOfficeBillingPeriod: data.virtualOfficeBillingPeriod }
            : { address: data.address },
        );
        break;
      case CompanyStepEntity.AnnaTaxes:
        await this._updateProduct(CompanyProduct.Taxes, Boolean(data.annaTaxes));
        break;
      case CompanyStepEntity.VatRegistration:
        await this._updateProduct(CompanyProduct.VatRegistration, Boolean(data.vatRegistration));
        break;
      case CompanyStepEntity.ConfirmationStatement:
        await this._updateProduct(CompanyProduct.ConfirmationStatement, Boolean(data.confirmationStatement));
        break;
      case CompanyStepEntity.Check:
        break;
      case CompanyStepEntity.Management:
        await this._updateDirectoryManagement(data);
        break;
      case CompanyStepEntity.PayeRegistration:
        await this._updatePayeRegistration(data);
        break;
      case CompanyStepEntity.CancellationCover:
        await this._updateCancellationCover(data);
        break;

      default:
        await this._companyStore.updateCompany(companyFormToCompanyUpdateData(data));
    }
  }

  private async _updateProduct(
    product: CompanyProduct,
    toggle: boolean,
    details?: DefaultCompanyProductDetailsData,
  ): Promise<void> {
    toggle
      ? await this._companyProductsStore.addProduct(product, details)
      : await this._companyProductsStore.deleteProduct(product);
  }
}
