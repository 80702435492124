import * as Sentry from "@sentry/browser";

const enhancementKey = Symbol("EnhancementKey");

function extractEnhancement(error: Error): Error | undefined {
  const enhancement = Object.getOwnPropertyDescriptor(error, enhancementKey)?.value;
  if (enhancement instanceof Error) {
    return enhancement;
  }
  return undefined;
}

export function enhanceExceptionEvent(event: Sentry.Event, hint: Sentry.EventHint): void {
  const exceptions = event.exception?.values;
  const enhancementStack =
    hint.originalException instanceof Error ? extractEnhancement(hint.originalException)?.stack : null;
  if (exceptions && enhancementStack) {
    const exception: Sentry.Exception = {
      type: "ErrorEnhancement",
      value: "Error enhancement",
      stacktrace: { frames: Sentry.defaultStackParser(enhancementStack, 1) },
    };
    exceptions.unshift(exception);
    event.tags = event.tags || {};
    event.tags.enhancement_test = true;
  }
}
