import { makeAutoObservable } from "mobx";

import { type DirectorFormAttributes } from "helpers/forms/directorTypes";
import { type AttributesClient } from "services/attributes/attributesClient";
import { type PersonalAttributeData, PersonalAttributeListCreateData } from "services/attributes/attributesTypes";

type DirectorAttributes = PersonalAttributeData[];

type DirectorAttributesState = Record<string, DirectorAttributes>;

export class DirectorAttributesStore {
  private readonly _attributesClient: AttributesClient;
  private readonly _state: DirectorAttributesState = {};

  constructor(attributesClient: AttributesClient) {
    this._attributesClient = attributesClient;

    makeAutoObservable(this);
  }

  get state(): DirectorAttributesState {
    return this._state;
  }

  tryGetForDirector(directorId: string): DirectorAttributes | undefined {
    return this._state[directorId];
  }

  async loadForDirector(directorId: string): Promise<void> {
    const attributes = await this._attributesClient.getAttributes(directorId);

    if (attributes) {
      this._state[directorId] = attributes;
    }
  }

  async updateForDirector(directorId: string, attributes: DirectorFormAttributes): Promise<void> {
    this._state[directorId] = await this._attributesClient.createAttributes(
      new PersonalAttributeListCreateData(directorId, attributes),
    );
  }
}
