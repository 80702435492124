import { CompanyProduct } from "services/companyProducts/companyProductsTypes";
import { type IssueFieldQuestionMap } from "services/issues/issuesStore";

export enum CompanyQuestion {
  Address = "COMPANY_ADDRESS",
  Description = "COMPANY_DESCRIPTION",
  Activities = "COMPANY_ACTIVITIES",
  Starting = "COMPANY_STARTING",
  AnnaTaxes = "COMPANY_INTERESTED_IN_ANNA_TAXES",
  VatRegistration = "COMPANY_VAT_REGISTRATION",
  ConfirmationStatement = "COMPANY_CONFIRMATION_STATEMENT",
  Check = "DIRECTOR_CHECK",
  Management = "DIRECTOR_MANAGEMENT",
  CancellationCover = "DIRECTOR_CANCELLATION_COVER",
  PayeRegistration = "DIRECTOR_PAYE_REGISTRATION",
}

export const ISSUE_FIELD_TO_QUESTION: IssueFieldQuestionMap<CompanyQuestion> = {
  country: CompanyQuestion.Address,
  address: CompanyQuestion.Address,
  nature_of_business: CompanyQuestion.Description,
  sic_codes: CompanyQuestion.Activities,
  starts_trading: CompanyQuestion.Starting,
  check: CompanyQuestion.Check,
};

export const QUESTION_TO_PRODUCT: { [key in CompanyQuestion]?: CompanyProduct } = {
  [CompanyQuestion.AnnaTaxes]: CompanyProduct.Taxes,
  [CompanyQuestion.VatRegistration]: CompanyProduct.VatRegistration,
  [CompanyQuestion.ConfirmationStatement]: CompanyProduct.ConfirmationStatement,
  [CompanyQuestion.Management]: CompanyProduct.DirectoryManagement,
  [CompanyQuestion.CancellationCover]: CompanyProduct.CancellationCover,
  [CompanyQuestion.PayeRegistration]: CompanyProduct.PayeRegistration,
};

export const QUESTION_TO_PATH: Record<CompanyQuestion, string> = {
  [CompanyQuestion.Address]: "address",
  [CompanyQuestion.Description]: "description",
  [CompanyQuestion.Activities]: "activities",
  [CompanyQuestion.Starting]: "starting",
  [CompanyQuestion.AnnaTaxes]: "taxes",
  [CompanyQuestion.VatRegistration]: "vat-registration",
  [CompanyQuestion.ConfirmationStatement]: "confirmation-statement",
  [CompanyQuestion.Check]: "check",
  [CompanyQuestion.CancellationCover]: "cancellation-cover",
  [CompanyQuestion.PayeRegistration]: "paye-registration",
  [CompanyQuestion.Management]: "management",
};
