import { type DependencyList, useMemo } from "react";
import { useServices } from "appServices";
import { addressDataToForm } from "helpers/forms/address";

import { getUseVirtualOffice, getVirtualOfficeBillingPeriod } from "helpers/forms/company";
import { isGbPricingPlan } from "helpers/pricingPlan";
import { type AddressData } from "services/address/addressTypes";
import { CompanyData, CompanyUpdateData } from "services/company/companyTypes";
import {
  CompanyProduct,
  type DefaultCompanyProductDetailsData,
  type VirtualOfficeProductDetailsData,
} from "services/companyProducts/companyProductsTypes";
import type { PricingPlanAu } from "services/pricingPlan/pricingPlanTypes";
import type { ServicesAu } from "servicesAu";
import type { CompanyFormValues } from "./companyTypes";

const getPricingPlanFormValue = (company?: CompanyData): PricingPlanAu | undefined => {
  if (!company?.pricingPlan) {
    return;
  }

  if (isGbPricingPlan(company.pricingPlan)) {
    return;
  }

  return company.pricingPlan;
};

const companyDataToCompanyForm = (
  company?: CompanyData,
  address?: AddressData,
  businessNameProductDetails?: DefaultCompanyProductDetailsData,
  virtualOfficeProductDetails?: VirtualOfficeProductDetailsData,
): CompanyFormValues | undefined => {
  if (!company?.companyName || !company.namePostfix) {
    return;
  }

  const values: CompanyFormValues = {
    name: company.companyName,
    namePostfix: company.namePostfix,
    pricingPlan: getPricingPlanFormValue(company),
    useVirtualOffice: getUseVirtualOffice(company),
    virtualOfficeBillingPeriod: getVirtualOfficeBillingPeriod(company, virtualOfficeProductDetails),
    address: addressDataToForm(address),
    stateOfRegistration: company.stateOfRegistration ?? undefined,
  };

  if (businessNameProductDetails) {
    values.businessName = businessNameProductDetails.name;
    values.businessNamePeriod = businessNameProductDetails.period;
  }

  return values;
};

export const companyFormToCompanyUpdateData = (companyForm: CompanyFormValues): CompanyUpdateData => {
  return new CompanyUpdateData(
    companyForm.name,
    companyForm.namePostfix,
    undefined,
    undefined,
    undefined,
    undefined,
    companyForm.stateOfRegistration,
    undefined,
  );
};

export const useCompanyFormInitialValue = (deps: DependencyList = []): CompanyFormValues | undefined => {
  const { companyStore, companyProductsStore, companyAddressStore } = useServices<ServicesAu>();

  return useMemo(
    () =>
      companyDataToCompanyForm(
        companyStore.company,
        companyAddressStore.address,
        companyProductsStore.getProductDetails(CompanyProduct.RegisterBusinessName),
        companyProductsStore.getProductDetails(CompanyProduct.VirtualOffice),
      ),
    deps,
  );
};
