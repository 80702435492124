import { HttpClient } from "@anna-money/anna-web-lib/api";
import { isObject } from "@anna-money/anna-web-lib/utils";
import { AuthenticationError, DeauthenticationError } from "auth/errors";

export class AuthClient {
  private readonly _client: HttpClient;

  constructor(apiUrl: string) {
    this._client = new HttpClient(apiUrl);
  }

  async getAccessToken(clientId: string, authCode: string, redirectUrl: string): Promise<string> {
    const request = await this._client.request({
      method: "post",
      url: "/token",
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
      data: JSON.stringify({
        client_id: clientId,
        code: authCode,
        redirect_uri: redirectUrl,
      }),
    });

    const response = await request.send();
    if (!response.isOk) {
      throw new AuthenticationError(response.diagnostics, "Authentication server error");
    }

    const content = response.data;
    if (!isObject(content) || typeof content.access_token !== "string") {
      throw new AuthenticationError(response.diagnostics, "Unexpected authenticator response");
    }

    return content.access_token;
  }

  async logout(): Promise<void> {
    const request = await this._client.request({
      method: "post",
      url: "/logout",
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    const response = await request.send();
    if (!response.isOk) {
      throw new DeauthenticationError(response.diagnostics, "Deauthentication server error");
    }
  }
}
