import { type AnyEventName, type EventPayload } from "@sumsub/websdk/types";
import { makeAutoObservable } from "mobx";
import type { AddressVerificationStore } from "services/addressVerification/addressVerificationStore";
import { type AnalyticsManager } from "services/analytics/analyticsManager";
import type { IdentityQuestionsStore } from "services/questions/gb/identityQuestionsStore";
import { type SumsubClient } from "services/sumsub/sumsubClient";
import { SumsubCheckFlowName } from "services/sumsub/sumsubTypes";
import { type SumsubToken } from "./types";

const MAX_POA_CHECKS = 10;

type DirectorPoaWidgetState = "waiting_for_bank" | "waiting_for_poa" | "success" | "error";

export class DirectorPoaWidgetStore {
  private _state: DirectorPoaWidgetState = "waiting_for_bank";
  private _token: SumsubToken;
  private _poaCheckCounter = 0;

  constructor(
    private readonly _sumsubClient: SumsubClient,
    private readonly _addressVerificationStore: AddressVerificationStore,
    private readonly _identityQuestionsStore: IdentityQuestionsStore,
    private readonly _analyticsManager: AnalyticsManager,
  ) {
    makeAutoObservable(this);
  }

  get token(): SumsubToken {
    return this._token;
  }

  private set token(value: SumsubToken) {
    this._token = value;
  }

  get state(): DirectorPoaWidgetState {
    return this._state;
  }

  set state(value: DirectorPoaWidgetState) {
    this._state = value;
  }

  async init(): Promise<void> {
    await this.getSumsubToken();
  }

  async getSumsubToken(): Promise<string> {
    this.token = await this._sumsubClient.getSumsubToken(SumsubCheckFlowName.ONE_CLICK_LEVEL);
    return this.token;
  }

  async onMessage(message: AnyEventName, payload: EventPayload<AnyEventName>): Promise<void> {
    this._analyticsManager.event(`sumsub-poa-message.${message}`, payload);

    switch (message) {
      case "idCheck.applicantReviewComplete":
        await this._onApplicantReviewComplete();
        break;
    }
  }

  private async _onApplicantReviewComplete(): Promise<void> {
    this.state = "waiting_for_poa";
    const interval = setInterval(async () => {
      if (this._poaCheckCounter === MAX_POA_CHECKS) {
        this.state = "error";
        clearInterval(interval);
        this._identityQuestionsStore.onOpenbankingCheckError();
        return;
      }

      this._poaCheckCounter += 1;
      const poaIsRequired = await this._addressVerificationStore.checkDirectorAddress();

      if (!poaIsRequired) {
        this.state = "success";
        clearInterval(interval);
      }
    }, 3000);
  }
}
