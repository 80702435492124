import { formatMoney } from "helpers/money";
import type { BillingItemData } from "services/billingItems/types";
import { BillingItem } from "services/checkoutBasket/checkoutBasketTypes";
import { VirtualOfficeBillingPeriod } from "services/companyProducts/companyProductsTypes";

export class BillingItemsStore {
  getData(billingItem: BillingItem, param?: unknown): BillingItemData {
    switch (billingItem) {
      case BillingItem.VirtualOffice: {
        return this._getVirtualOfficeData(param as VirtualOfficeBillingPeriod);
      }
      case BillingItem.Taxes:
        return {
          title: "+Taxes",
          description: "Take the stress out of tax admin by letting ANNA + Taxes do the work for you",
          price: `${formatMoney(3, { monthly: true })}, for 3 months`,
          vat: formatMoney(0.6),
          remark: `${formatMoney(24)} after that`,
        };
      case BillingItem.VatRegistration:
        return {
          title: "VAT registration with HMRC",
          description: "On demand VAT Registration on your behalf",
          price: formatMoney(39.9),
          vat: formatMoney(7.98),
        };
      case BillingItem.ConfirmationStatement:
        return {
          title: "Confirmation statement filing",
          description:
            "ANNA can help you to file your company’s confirmation statement (annual return) " +
            "in time for the deadline for you",
          price: formatMoney(49.9),
          vat: formatMoney(9.98),
        };
      case BillingItem.DirectoryManagement:
        return {
          title: "Director management service",
          description: "Director appointments and changes on demand,  " + "all with full legal compliance ",
          price: formatMoney(29.90),
          vat: formatMoney(5.98),
        };
      case BillingItem.CancellationCover:
        return {
          title: "Company cancellation cover",
          description:
            "Have you changed mind and decided you no longer need your company? " +
            "We’ll take care of the paperwork and close the company for you",
          price: formatMoney(49.9),
          vat: formatMoney(9.98),
        };
      case BillingItem.PayeRegistration:
        return {
          title: "PAYE Employer registration",
          description:
            "Want to pay a salary to yourself or company employees? " +
            "Let us register your company for PAYE with HMRC",
          price: formatMoney(39.9),
          vat: formatMoney(7.98),
        };
      case BillingItem.SharesManagement:
        return {
          title: "Share management",
          description:
            "Issue and transfer of shares, documentation management and record updates to stay compliant with Companies House",
          price: formatMoney(79.99),
          vat: formatMoney(16.0),
        };
      case BillingItem.FormationExpress:
        return {
          title: "Same-day registration",
          description: "Same day company registration",
          price: formatMoney(99.9),
          vat: formatMoney(19.98),
        };
    }
  }

  private _getVirtualOfficeData(
    billingPeriod: VirtualOfficeBillingPeriod = VirtualOfficeBillingPeriod.Monthly,
  ): BillingItemData {
    const prices = {
      [VirtualOfficeBillingPeriod.Monthly]: {
        price: formatMoney(19.90, { monthly: true }),
        vat: formatMoney(3.98),
      },
      [VirtualOfficeBillingPeriod.Yearly]: {
        price: formatMoney(15, { monthly: true }),
        vat: formatMoney(3),
      },
    };
    const price = prices[billingPeriod].price;
    const vat = prices[billingPeriod].vat;
    const priceSuffix = ", billed " + (billingPeriod === VirtualOfficeBillingPeriod.Monthly ? "monthly" : "annually");

    return {
      title: "Virtual office address",
      description: "Keep your personal address private with same day scanning and mail forwarding",
      price,
      priceText: price + priceSuffix,
      vat,
      priceTotal: billingPeriod === VirtualOfficeBillingPeriod.Yearly ? formatMoney(90) + "/year" : undefined,
      vatTotal: billingPeriod === VirtualOfficeBillingPeriod.Yearly ? formatMoney(18) : undefined,
    };
  }
}
