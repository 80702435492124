import { AnnaErrorBase, type AnnaErrorOptions, CustomError } from "@anna-money/anna-web-lib/utils";

import { type HttpRequestDiagnostics, HttpRequestError } from "@anna-money/anna-web-lib/api";

@CustomError("AuthenticationError")
export class AuthenticationError extends HttpRequestError {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(diagnostics: HttpRequestDiagnostics, message: string, options?: AnnaErrorOptions) {
    super(diagnostics, message, options);
  }
}

@CustomError("DeauthenticationError")
export class DeauthenticationError extends HttpRequestError {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(diagnostics: HttpRequestDiagnostics, message: string, options?: AnnaErrorOptions) {
    super(diagnostics, message, options);
  }
}

@CustomError("UnauthenticatedError")
export class UnauthenticatedError extends AnnaErrorBase {
  constructor() {
    super("Authentication lost");
  }
}
