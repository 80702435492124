import { type ApiClient } from "@anna-money/anna-web-lib/api";

import {
  type PersonalAttributeData,
  type PersonalAttributeListCreateData,
  PersonalAttributeListData,
} from "services/attributes/attributesTypes";

export class AttributesClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  async getAttributes(id: string): Promise<PersonalAttributeData[] | undefined> {
    const response = await this._apiClient.get(`api/formations/personal-attributes?director_id=${id}`);
    const attributeList = response.getData(PersonalAttributeListData);

    return attributeList.attributes.length ? attributeList.attributes : undefined;
  }

  async createAttributes(data: PersonalAttributeListCreateData): Promise<PersonalAttributeData[]> {
    const response = await this._apiClient.post("api/formations/personal-attributes", data);

    return response.getData(PersonalAttributeListData).attributes;
  }
}
