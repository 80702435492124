import { JsonProperty, Serializable } from "@anna-money/anna-web-lib/serializerOld";

export enum AustralianCounty {
  AustralianCapitalTerritory = "Australian Capital Territory",
  NewSouthWales = "New South Wales",
  NorthernTerritory = "Northern Territory",
  Queensland = "Queensland",
  SouthAustralia = "South Australia",
  Tasmania = "Tasmania",
  Victoria = "Victoria",
  WesternAustralia = "Western Australia",
  CocosIslands = "Cocos (Keeling) Islands",
  ChristmasIsland = "Christmas Island",
  JervisBayTerritory = "Jervis Bay Territory",
  NorfolkIsland = "Norfolk Island",
}

@Serializable()
export class AddressData {
  constructor(
    @JsonProperty() readonly id: string,
    @JsonProperty() readonly created: string,
    @JsonProperty() readonly updated: string,
    @JsonProperty() readonly premise: string,
    @JsonProperty() readonly street: string,
    @JsonProperty() readonly town: string,
    @JsonProperty() readonly country: string,
    @JsonProperty() readonly postcode: string,
    @JsonProperty({ required: false, type: String }) readonly companyId: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly directorIdRegistered: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly directorIdService: Nullable<string>,
    // AU
    @JsonProperty({ required: false, type: String }) readonly businessCompanyId: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly county: Nullable<string>,
    @JsonProperty({ required: false, type: Boolean }) readonly isOwnedByCompany: Nullable<boolean>,
    @JsonProperty({ required: false, type: String }) readonly occupierName: Nullable<string>,
    @JsonProperty({ required: false, type: Boolean }) readonly occupierDeclaration: Nullable<boolean>,
    @JsonProperty({ required: false, type: Boolean }) readonly isUsualResidentalAddress: Nullable<boolean>,
    @JsonProperty({ required: false, type: String }) readonly asicApprovalNumber: Nullable<string>,
  ) {}
}

@Serializable()
export class AddressListData {
  constructor(
    @JsonProperty({ type: AddressData, isArray: true })
    readonly addresses: AddressData[],
  ) {}
}

@Serializable()
export class AddressCreateData {
  constructor(
    @JsonProperty() readonly premise: string,
    @JsonProperty() readonly street: string,
    @JsonProperty() readonly town: string,
    @JsonProperty() readonly postcode: string,
    // AU
    @JsonProperty({ required: false, type: String }) readonly county: Nullable<string>,
    @JsonProperty({ required: false, type: Boolean }) readonly isOwnedByCompany: Nullable<boolean>,
    @JsonProperty({ required: false, type: String }) readonly occupierName: Nullable<string>,
    @JsonProperty({ required: false, type: Boolean }) readonly occupierDeclaration: Nullable<boolean>,
    @JsonProperty({ required: false, type: Boolean }) readonly isUsualResidentalAddress: Nullable<boolean>,
    @JsonProperty({ required: false, type: String }) readonly asicApprovalNumber: Nullable<string>,
    //
    @JsonProperty({ required: false, type: String }) readonly companyId: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly directorIdRegistered: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly directorIdRegisteredPrevious: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly companyActivityId: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly businessCompanyId: Nullable<string>,
  ) {}
}

@Serializable()
export class AddressUpdateData {
  constructor(
    @JsonProperty() readonly premise: string,
    @JsonProperty() readonly street: string,
    @JsonProperty() readonly town: string,
    @JsonProperty() readonly postcode: string,
    // AU
    @JsonProperty({ required: false, type: String }) readonly county: Nullable<string>,
    @JsonProperty({ required: false, type: Boolean }) readonly isOwnedByCompany: Nullable<boolean>,
    @JsonProperty({ required: false, type: String }) readonly occupierName: Nullable<string>,
    @JsonProperty({ required: false, type: Boolean }) readonly occupierDeclaration: Nullable<boolean>,
    @JsonProperty({ required: false, type: Boolean }) readonly isUsualResidentalAddress: Nullable<boolean>,
    @JsonProperty({ required: false, type: String }) readonly asicApprovalNumber: Nullable<string>,
  ) {}
}
