import { type ApiClient } from "@anna-money/anna-web-lib/api";

import { AddressVerificationResult } from "services/addressVerification/addressVerificationTypes";

export class AddressVerificationClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  async getAddressVerification(addressId: string): Promise<AddressVerificationResult> {
    const response = await this._apiClient.get(`api/formations/v2/addresses/verification/${addressId}`);

    return response.getData(AddressVerificationResult);
  }
}
