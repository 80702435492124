import { AnnaError } from "@anna-money/anna-web-lib/utils";
import { makeAutoObservable } from "mobx";

import type { LoadableState } from "types/loadableState";
import type { BusinessActivitiesClient } from "./businessActivitiesClient";
import { type BusinessActivityData } from "./businessActivitiesTypes";

type BusinessActivitiesState = LoadableState<"success">;

export class BusinessActivitiesStore {
  private _state: BusinessActivitiesState = "loading";
  private _activities: BusinessActivityData[] = [];

  constructor(private readonly _businessActivitiesClient: BusinessActivitiesClient) {
    makeAutoObservable(this);
  }

  get state(): BusinessActivitiesState {
    return this._state;
  }

  private set state(value: BusinessActivitiesState) {
    this._state = value;
  }

  get activities(): BusinessActivityData[] {
    return this._activities;
  }

  private set activities(value: BusinessActivityData[]) {
    this._activities = value;
  }

  async init(): Promise<void> {
    this.state = "loading";

    try {
      this.activities = await this._businessActivitiesClient.getBusinessActivities();
      this.state = "success";
    } catch (e) {
      console.error(new AnnaError("Couldn't load business activities", { cause: e }));

      this.state = "error";
    }
  }

  getActivityById(id: string): BusinessActivityData {
    const activity = this.activities.find((activity) => activity.id === id);
    if (!activity) {
      throw new AnnaError(`No activity with id ${id}`);
    }
    return activity;
  }

  getActivityByData(code: string, description?: string): BusinessActivityData {
    const activity = this.activities.find(
      (activity) => activity.code === code && (description ? activity.description === description : true),
    );
    if (!activity) {
      throw new AnnaError(`No activity with code ${code}`);
    }
    return activity;
  }
}
