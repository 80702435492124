import { type AnalyticsManager } from "services/analytics/analyticsManager";
import { type BasketQuestionsStore } from "services/questions/gb/basketQuestionsStore";
import { type CompanyQuestionsStore } from "services/questions/gb/companyQuestionsStore";
import { type DirectorQuestionsStore } from "services/questions/gb/directorQuestionsStore";
import { type IdentityQuestionsStore } from "services/questions/gb/identityQuestionsStore";
import { type ParticularQuestionStore } from "services/questions/gb/particularsQuestionsStore";
import { RegisterFormStep } from "services/registerForm/registerFormStep";
import { RegisterFormSteps } from "services/registerForm/registerFormSteps";
import { RegisterFormStepsStoreBase } from "services/registerForm/registerFormStepsStoreBase";
import { IncoroporationFormStep } from "./constants";
import type { InitQuestionStore } from "services/questions/gb/initQuestionStore";

export class IncorporationRegisterFormStepsStore extends RegisterFormStepsStoreBase<IncoroporationFormStep> {
  protected readonly _steps: RegisterFormSteps<IncoroporationFormStep> = new RegisterFormSteps<IncoroporationFormStep>([
    new RegisterFormStep(IncoroporationFormStep.CONSENT, "Before you get started"),
    new RegisterFormStep(IncoroporationFormStep.BEFORE_START, "Before you get started", this._initQuestionStore),
    new RegisterFormStep(IncoroporationFormStep.DIRECTOR, "Personal Details", this._directorQuestionsStore),
    new RegisterFormStep(IncoroporationFormStep.COMPANY, "About your business", this._companyQuestionsStore),
    new RegisterFormStep(IncoroporationFormStep.SHARES, "Particulars", this._particularsQuestionsStore),
    new RegisterFormStep(IncoroporationFormStep.IDENTITY, "Confirm identity", this._identityQuestionsStore),
    new RegisterFormStep(IncoroporationFormStep.BASKET, "Your basket", this._basketQuestionsStore),
    new RegisterFormStep(IncoroporationFormStep.CONFIRM, "Confirm and submit"),
    new RegisterFormStep(IncoroporationFormStep.CHARGE, "Confirm and submit"),
    new RegisterFormStep(IncoroporationFormStep.COMPLETE, "Complete"),
  ]);
  readonly confirmStep: IncoroporationFormStep = IncoroporationFormStep.CONFIRM;
  readonly competeStep: IncoroporationFormStep = IncoroporationFormStep.COMPLETE;

  constructor(
    private readonly _companyQuestionsStore: CompanyQuestionsStore,
    private readonly _directorQuestionsStore: DirectorQuestionsStore,
    private readonly _particularsQuestionsStore: ParticularQuestionStore,
    private readonly _identityQuestionsStore: IdentityQuestionsStore,
    private readonly _basketQuestionsStore: BasketQuestionsStore,
    protected readonly _analyticsManager: AnalyticsManager,
    protected readonly _initQuestionStore: InitQuestionStore,
  ) {
    super(_analyticsManager);
  }

  protected _getPreviousStep(): IncoroporationFormStep {
    if (this.activeStep.type === IncoroporationFormStep.BASKET) {
      return IncoroporationFormStep.SHARES;
    }

    return super._getPreviousStep();
  }
}
