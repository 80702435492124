import { type CompanyStore } from "services/company/companyStore";
import { type FormationExpressStore } from "services/formationExpress/formationExpressStore";
import { IssueKey, type IssuesStore } from "services/issues/issuesStore";
import { QuestionsStore } from "services/questions/questionsStore";
import { InitQuestions } from "./initConstants";

export class InitQuestionStore extends QuestionsStore<InitQuestions> {
  protected _issueKey = IssueKey.Company;

  constructor(
    private readonly _companyStore: CompanyStore,
    private readonly _formationExpressStore: FormationExpressStore,
    protected readonly _issuesStore: IssuesStore,
  ) {
    super(InitQuestions, _issuesStore);
  }

  protected _getRelevantQuestion(): InitQuestions | undefined {
    const { company } = this._companyStore;

    if (!company?.companyName) {
      return InitQuestions.Name;
    }

    if (!company.pricingPlan) {
      return InitQuestions.PricingPlan;
    }

    if (!this._formationExpressStore.needToShow) {
      return InitQuestions.SameDayRegistration;
    }

    return undefined;
  }

  protected _shouldSkipQuestion(question: InitQuestions): boolean {
    switch (question) {
      case InitQuestions.SameDayRegistration:
        return !this._formationExpressStore.needToShow;
    }

    return super._shouldSkipQuestion(question);
  }
}
