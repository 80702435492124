import type { IssuesStore } from "services/issues/issuesStore";
import type { PricingPlanStore } from "services/pricingPlan/gb/pricingPlanStore";
import { QuestionsStore } from "services/questions/questionsStore";
import { PARTICULARS_QUESTION_TO_PRODUCT, ParticularsQuestion } from "./particularsConstants";
import type { DirectorStore } from "services/director/directorStore";

export class ParticularQuestionStore extends QuestionsStore<ParticularsQuestion> {
  constructor(
    private readonly _pricingPlanStore: PricingPlanStore,
    private readonly _directorStore: DirectorStore,
    protected readonly _issuesStore: IssuesStore,
  ) {
    super(ParticularsQuestion, _issuesStore);
  }

  // This function returns a ParticularsQuestion if the company has a totalSharesNum or totalCapitalGbp
  protected _getRelevantQuestion(): ParticularsQuestion | undefined {
    const { directors } = this._directorStore;

    const isShareHolderMissingShares =
      directors.filter((director) => !director.ownsSharesNum && director.isShareholder).length > 0;

    if (isShareHolderMissingShares) {
      return ParticularsQuestion.Particulars;
    }
    return undefined;
  }

  protected _shouldSkipQuestion(question: ParticularsQuestion): boolean {
    const product = PARTICULARS_QUESTION_TO_PRODUCT[question];

    if (product && this._pricingPlanStore.isProductIncluded(product)) {
      return true;
    }

    return super._shouldSkipQuestion(question);
  }
}
