import { type ValidationApiError } from "@anna-money/anna-web-lib/api";
import { convertSnakeCaseToCamel } from "helpers/serialization";
import { type FormSubmitResult, FormValidationError } from "types/form";

export const extractBackendValidationError = (error: ValidationApiError): FormSubmitResult => {
  // @ts-expect-error todo fix for prorer works with getErrors
  const validationErrors = error._validationErrors;
  if (validationErrors) {
    const key = convertSnakeCaseToCamel(validationErrors[0].id);
    switch (validationErrors[0].id) {
      case "postcode":
      case "town":
        return new FormValidationError({
          address: {
            [key]: validationErrors[0].title,
          },
        });
    }
    return new FormValidationError({
      [key]: validationErrors[0].title,
    });
  }

  return new FormValidationError(error.message);
};
