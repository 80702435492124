const isKeyInMap = <M extends object>(key: PropertyKey, map: M): key is keyof M => {
  return key in map;
};

export const getFromMapByKey = <M extends object>(map: M, key?: PropertyKey): M[keyof M] | undefined => {
  if (!key) {
    return undefined;
  }

  if (isKeyInMap(key, map)) {
    return map[key];
  }

  return undefined;
};
