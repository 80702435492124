import { CompanyCountry } from "services/company/companyTypes";

export enum IncoroporationFormStep {
  CONSENT = "CONSENT",
  BEFORE_START = "BEFORE_START",
  COMPANY = "COMPANY",
  DIRECTOR = "DIRECTOR",
  SHARES = "SHARES",
  IDENTITY = "IDENTITY",
  BASKET = "BASKET",
  CONFIRM = "CONFIRM",
  CHARGE = "CHARGE",
  COMPLETE = "COMPLETE",
}

export const FORM_STEP_TO_PATH: Record<IncoroporationFormStep, string> = {
  [IncoroporationFormStep.CONSENT]: "consent",
  [IncoroporationFormStep.BEFORE_START]: "before-start",
  [IncoroporationFormStep.COMPANY]: "company",
  [IncoroporationFormStep.DIRECTOR]: "director",
  [IncoroporationFormStep.SHARES]: "particulars",
  [IncoroporationFormStep.IDENTITY]: "identity",
  [IncoroporationFormStep.BASKET]: "basket",
  [IncoroporationFormStep.CONFIRM]: "confirm",
  [IncoroporationFormStep.CHARGE]: "charge",
  [IncoroporationFormStep.COMPLETE]: "complete",
};

export const COUNTRY_NAME_TO_CODE: Record<string, CompanyCountry> = {
  ENGLAND: CompanyCountry.EW,
  "ENGLAND & WALES": CompanyCountry.EW,
  "NORTHERN IRELAND": CompanyCountry.NI,
  WALES: CompanyCountry.WA,
  SCOTLAND: CompanyCountry.SC,
};
