export const parseDate = (date: string): Date => {
  const splittedDate = date.split("/").map((item) => (item[0] === "0" ? parseInt(item[1] ?? "") : parseInt(item)));
  const [day, month, year] = splittedDate;
  if (day === undefined || month === undefined || year === undefined) {
    throw new Error("Invalid date format");
  }
  return new Date(year, month - 1, day);
};

const normalizeDate = (date: Date): { day: string; month: string; year: string } => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  let dayStr = day.toString();
  let monthStr = month.toString();

  if (day < 10) {
    dayStr = "0" + day.toString();
  }

  if (month < 10) {
    monthStr = `0${month}`;
  }

  return {
    day: dayStr,
    month: monthStr,
    year: date.getFullYear().toString(),
  };
};

export const formatDateYYYYMMDD = (dateString: string): string => {
  const date = parseDate(dateString);
  const normalizedDate = normalizeDate(date);
  return `${normalizedDate.year}-${normalizedDate.month}-${normalizedDate.day}`;
};

export const formatDateDDMMYYYY = (dateString: string): string => {
  const date = new Date(dateString);
  const normalizedDate = normalizeDate(date);

  return `${normalizedDate.day}/${normalizedDate.month}/${normalizedDate.year}`;
};
