import { type CustomSerializer, SerializerOld } from "@anna-money/anna-web-lib/serializerOld";
import { convertCase } from "@anna-money/anna-web-lib/utils";
import { Datetime } from "types/date";
import { Serializer } from "@anna-money/anna-web-lib/serializer";

export const convertCamelCaseToSnake = convertCase.bind(null, "camel", "snake");
export const convertSnakeCaseToCamel = convertCase.bind(null, "snake", "camel");

export const DatetimeSerializer: CustomSerializer<string, Datetime> = {
  type: Datetime,
  serialize: (x) => x.toISOString(),
  deserialize: (x) => {
    if (typeof x !== "string") {
      throw new Error(`Expected date field to be a string but ${x as string} received`);
    }
    return Datetime.parse(x);
  },
};

export const apiSerializer = new SerializerOld({
  formatPropertyNames: convertCamelCaseToSnake,
  propertiesRequired: true,
  serializers: [DatetimeSerializer],
});

export const apiSerializerNew = new Serializer(convertCamelCaseToSnake);
