import { JsonProperty, Serializable } from "@anna-money/anna-web-lib/serializerOld";

export enum CardBrand {
  amex = "AMEX",
  diners = "DINERS",
  discover = "DISCOVER",
  jcb = "JCB",
  mastercard = "MASTERCARD",
  unionpay = "UNIONPAY",
  visa = "VISA",
  unknown = "UNKNOWN",
}

@Serializable()
export class PaymentMethod {
  constructor(
    @JsonProperty() readonly cardBrand: CardBrand,
    @JsonProperty() readonly isActive: boolean,
    @JsonProperty() readonly last4CardNumber: string,
    @JsonProperty() readonly type: string,
  ) {}
}

@Serializable()
export class PaymentMethodSetupIntent {
  constructor(
    @JsonProperty() readonly clientSecret: string,
    @JsonProperty() readonly id: string,
  ) {}
}

@Serializable()
export class PaymentMethodResponseData {
  constructor(
    @JsonProperty({ type: PaymentMethod })
    readonly paymentMethods: PaymentMethod[],
  ) {}
}
