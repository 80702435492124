/* eslint-disable @typescript-eslint/only-throw-error */
import { AnnaError } from "@anna-money/anna-web-lib/utils";
import { makeAutoObservable } from "mobx";

import { type IssuesClient } from "./issuesClient";
import { type IssueData } from "./issuesTypes";

export enum IssueKey {
  Company = "company",
  Director = "director",
}

export type IssueFieldQuestionMap<T> = Record<string, T>;

export type ClientIssueData = {
  id: string;
  key: IssueKey;
  field: string;
};

export interface IIssuesStore {
  issues: IssueData[];
  resolveIssue: (issueId: string) => Promise<void>;
  hasIssues: () => boolean;
  getFirstIssueForKey: (key: IssueKey) => ClientIssueData | undefined;
  getIssuesForKey: (key: IssueKey) => ClientIssueData[];
  hasIssueForKey: (key: IssueKey) => boolean;
}

export class IssuesStore implements IIssuesStore {
  private readonly _companyIssuesClient: IssuesClient;

  private _issues: IssueData[] = [];
  private _parsedIssues: ClientIssueData[] = [];

  constructor(companyIssuesClient: IssuesClient) {
    this._companyIssuesClient = companyIssuesClient;

    makeAutoObservable(this);
  }

  get issues(): IssueData[] {
    return this._issues;
  }

  async init(): Promise<void> {
    await this._getUnresolvedIssues();
  }

  async resolveIssue(issueId: string): Promise<void> {
    await this._companyIssuesClient.resolveIssue(issueId);

    await this._getUnresolvedIssues();
  }

  hasIssues(): boolean {
    return Boolean(this._issues.length);
  }

  hasIssuesWithPath(): boolean {
    return this._issues.some((issue) => !!issue.path);
  }

  hasIssueForKey(key: IssueKey): boolean {
    for (const issue of this._parsedIssues) {
      if (issue.key === key) {
        return true;
      }
    }

    return false;
  }

  getFirstIssueForKey(key: IssueKey): ClientIssueData | undefined {
    return this._parsedIssues.find((issue) => issue.key === key);
  }

  getIssuesForKey(key: IssueKey): ClientIssueData[] {
    return this._parsedIssues.filter((issue) => issue.key === key);
  }

  private async _getUnresolvedIssues(): Promise<void> {
    const issues = await this._companyIssuesClient.getIssues();

    this._issues = issues.filter((issue) => !issue.resolved);

    this._parsedIssues = this._issues.map((issue) => {
      const splittedPath = issue.path.split(".");
      const step = splittedPath[0]?.toLowerCase();
      if (step !== IssueKey.Company && step !== IssueKey.Director) {
        throw new AnnaError("Unexpected step name from the issue");
      }
      const field = splittedPath[1]?.toLowerCase() ?? "";

      return {
        id: issue.id,
        key: step,
        field,
      };
    });
  }
}
