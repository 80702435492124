import { AnnaError } from "@anna-money/anna-web-lib/utils";
import { type IAnalyticsManager } from "services/analytics/analyticsManager";
import { QuestionStoreError } from "services/questions/questionsStore";
import { RegisterFormStep } from "./registerFormStep";
import { RegisterFormSteps } from "./registerFormSteps";

export abstract class RegisterFormStepsStoreBase<T> {
  protected abstract readonly _steps: RegisterFormSteps<T>;
  abstract readonly confirmStep: T;
  abstract readonly competeStep: T;

  get steps(): RegisterFormSteps<T> {
    return this._steps;
  }

  get activeStep(): RegisterFormStep<T> {
    return this.steps.activeStep;
  }

  get activeStepIndex(): number {
    return this.steps.activeStepIndex;
  }

  get isFirstStep(): boolean {
    return this.steps.activeStepIndex === 0;
  }

  get isLastStep(): boolean {
    return this.steps.activeStepIndex === this.steps.total - 1;
  }

  get activeStepTestId(): string {
    if (!this.activeStep.controller) {
      return (this.activeStep.type as string).toLowerCase();
    }
    const question = this.activeStep.controller.activeQuestionKey as string;
    return question.toLowerCase().replaceAll("_", "-");
  }

  protected constructor(protected readonly _analyticsManager: IAnalyticsManager) {}

  goPreviousQuestion(): void {
    const { controller } = this.activeStep;

    if (!controller) {
      this.setStep(this._getPreviousStep());
      return;
    }

    try {
      controller.goBack();
    } catch (e) {
      if (e instanceof QuestionStoreError) {
        this.setStep(this._getPreviousStep());
        return;
      }

      throw e;
    }
  }

  async goNextQuestion(): Promise<void> {
    const { controller } = this.activeStep;

    if (!controller) {
      this._goNextStep();
      return;
    }

    if (controller.hasIssues()) {
      await controller.resoveIssueIfNeeded();
      this.goToNextIssue();
      return;
    }

    try {
      controller.goNext();
    } catch (e) {
      if (e instanceof QuestionStoreError) {
        this._goNextStep();
        return;
      }

      throw e;
    }
  }

  getStepByName(question: string): T | undefined {
    for (const step of this._steps) {
      if (step.type === question || step.controller?.hasQuestionByName(question)) {
        return step.type;
      }
    }

    return undefined;
  }

  setStep(step: T): void {
    this._steps.activateStep(step);

    if (this._steps.activeStep.controller) {
      this._steps.activeStep.controller.goToRelevantQuestion();
    }

    this._analyticsManager.event("load-step", { name: step });
  }

  goToNextIssue(): void {
    for (const step of this._steps) {
      if (!step.controller) {
        continue;
      }

      if (step.controller.hasIssues()) {
        this.setStep(step.type);
        return;
      }
    }

    this.setStep(this.confirmStep);
  }

  protected _getPreviousStep(): T {
    if (!this._steps.previousStep) {
      throw new AnnaError("No previous step during attempt to go back");
    }

    return this._steps.previousStep.type;
  }

  private _goNextStep(): void {
    if (!this._steps.nextStep) {
      throw new AnnaError("No next step during attempt to go forward");
    }
    this.setStep(this._steps.nextStep.type);
  }
}
