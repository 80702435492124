import { ApiClient } from "@anna-money/anna-web-lib/api";
import { makeAutoObservable } from "mobx";
import { type NavigateFunction } from "react-router-dom";
import { BaseServices } from "services";
import { UserStore } from "services/user/userStore";
import { AuthenticationHandler } from "./auth/authenticationApiHandler";
import { type Config } from "./config/config";
import { apiSerializer, apiSerializerNew } from "./helpers/serialization";
import { FeatureClient } from "./services/feature/featureClient";
import { FeatureStore } from "./services/feature/featureStore";
import { ProfileClient } from "./services/profile/profileClient";
import { ProfileStore } from "./services/profile/profileStore";
import { UserClient } from "./services/user/userClient";
import { UserClusterClient } from "./services/userCluster/userClusterClient";
import { ServicesAu } from "./servicesAu";
import { ServicesGb } from "./servicesGb";

type AppBootstrapState = "loading" | "error" | AppBootstrap;

export class AppBootstrapStore {
  private _state: AppBootstrapState = "loading";
  readonly baseServices: BaseServices;

  constructor(config: Config) {
    makeAutoObservable(this);
    this.baseServices = new BaseServices(config);
  }

  get state(): AppBootstrapState {
    return this._state;
  }

  private set state(value: AppBootstrapState) {
    this._state = value;
  }

  async initialise(navigate: NavigateFunction): Promise<void> {
    this.state = "loading";
    try {
      this.state = await AppBootstrap.create(this.baseServices, navigate);
    } catch (e) {
      this.state = "error";
      console.error(e);
    }
  }
}

class AppBootstrap {
  constructor(readonly services: ServicesGb | ServicesAu) {}

  static async create(baseServices: BaseServices, navigate: NavigateFunction): Promise<AppBootstrap> {
    const authenticationHandler = new AuthenticationHandler(baseServices.authenticator, baseServices.authStore);
    const apiClient = new ApiClient({
      apiUrl: baseServices.config.annaApiUrl,
      apiSerializerOld: apiSerializer,
      apiSerializerNew: apiSerializerNew,
      handlers: [authenticationHandler],
    });
    const userClient = new UserClient(apiClient);
    const [userStore, profileStore, featureStore] = await Promise.all([
      UserStore.create(userClient, new UserClusterClient(apiClient)),
      ProfileStore.create(new ProfileClient(apiClient)),
      FeatureStore.create(new FeatureClient(apiClient)),
    ]);

    const services = baseServices.config.isAU
      ? new ServicesAu(
          baseServices,
          authenticationHandler,
          apiClient,
          userClient,
          userStore,
          profileStore,
          featureStore,
          navigate,
        )
      : new ServicesGb(
          baseServices,
          authenticationHandler,
          apiClient,
          userClient,
          userStore,
          profileStore,
          featureStore,
          navigate,
        );

    baseServices.sentryHost.configure(userStore);
    baseServices.analyticsManager.configure(userStore);

    return new AppBootstrap(services);
  }
}
