import { type ApiClient } from "@anna-money/anna-web-lib/api";
import { MagicLinkData, QrCodeData } from "./deepLinkTypes";

export class DeepLinkClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  async getQrCode(magicLink: string): Promise<string> {
    const response = await this._apiClient.post("api/qr-code/i/qr-code/generate", { text: magicLink });
    const qrCodeData = response.getData(QrCodeData);
    const ir = await fetch(`data:${qrCodeData.mediaType};${qrCodeData.encoding},${qrCodeData.data}`);
    const blob = await ir.blob();

    return URL.createObjectURL(blob);
  }

  async getMagicLink(): Promise<string> {
    const response = await this._apiClient.get("api/auth/v2/magic-link/generate");

    return response.getData(MagicLinkData).magicLink;
  }
}
