export enum InitQuestions {
  Name = "COMPANY_NAME",
  PricingPlan = "COMPANY_PRICING_PLAN",
  SameDayRegistration = "COMPANY_SAME_DAY_REGISTRATION",
}

export const QUESTION_TO_PATH: Record<InitQuestions, string> = {
  [InitQuestions.Name]: "name",
  [InitQuestions.PricingPlan]: "package",
  [InitQuestions.SameDayRegistration]: "same-day-registration",
};
