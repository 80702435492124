import { AnnaError } from "@anna-money/anna-web-lib/utils";
import { makeAutoObservable } from "mobx";

import { type CompanyStore } from "services/company/companyStore";
import type { CompanyBusinessActivitiesStore } from "services/companyBusinessActivities/gb/companyBusinessActivitiesStore";
import { type SicCodesClient } from "services/sicCodes/sicCodesClient";
import { SicCodesRecommendRequestData } from "services/sicCodes/sicCodesTypes";
import type { LoadableState } from "types/loadableState";

export type RecommendedSicCodes = string[];

type RecommendedSicCodesState = LoadableState<RecommendedSicCodes>;

export class RecommendedSicCodesStore {
  private _state: RecommendedSicCodesState = "loading";

  constructor(
    private readonly _companyStore: CompanyStore,
    private readonly _companyBusinessActivitiesStore: CompanyBusinessActivitiesStore,
    private readonly _sicCodesClient: SicCodesClient,
  ) {
    makeAutoObservable(this);
  }

  get state(): RecommendedSicCodesState {
    return this._state;
  }

  get sicCodes(): RecommendedSicCodes {
    if (this._state === "loading" || this._state === "error") {
      return [];
    }

    return this._state;
  }

  async init(): Promise<void> {
    this._state = "loading";
    const activities = this._companyBusinessActivitiesStore.activities;

    try {
      this._state = await this._sicCodesClient.getRecommendedSicCodes(
        new SicCodesRecommendRequestData(
          this._companyStore.company?.natureOfBusiness || "",
          activities ? activities.map((item) => item.code) : [],
        ),
      );
    } catch (e) {
      console.error(new AnnaError("Couldn't load recommended sic codes", { cause: e }));

      this._state = "error";
    }
  }
}
