import { ApiError, type HttpRequestDiagnostics, HttpRequestError, NetworkError } from "@anna-money/anna-web-lib/api";
import {
  AnnaErrorBase,
  type Nullable,
  UnexpectedValueError,
  instancePredicate,
  unwrapError,
} from "@anna-money/anna-web-lib/utils";
import { type ErrorTypes, isWebticsError } from "@anna-money/webtics";
import * as Sentry from "@sentry/browser";
import { reaction } from "mobx";
import { UnauthenticatedError } from "auth/errors";
import type { UserStore } from "services/user/userStore";
import type { UserData } from "services/user/userTypes";
import { enhanceExceptionEvent } from "./sentryExceptionEnhancer";

let preventSending = false;
window.addEventListener("beforeunload", () => {
  preventSending = true;
  // In case if we didn't actually leave
  setTimeout(() => {
    preventSending = false;
  }, 100);
});

window.addEventListener("pagehide", () => {
  preventSending = true;
});

window.addEventListener("pageshow", () => {
  preventSending = false;
});

export class SentryHost {
  private static _initialised = false;

  static initialise({
    dsn,
    appUrl,
    appVersion,
    appEnvironment,
  }: {
    dsn: string;
    appUrl: string;
    appVersion: string;
    appEnvironment: string;
  }): void {
    Sentry.init({
      dsn,
      allowUrls: [appUrl],
      release: appVersion,
      environment: appEnvironment,
      integrations: [
        Sentry.extraErrorDataIntegration(),
        Sentry.captureConsoleIntegration({
          levels: ["warn", "error"],
        }),
      ],
      beforeSend: (event, hint) => {
        if (preventSending || shouldIgnoreEvent(event)) {
          return null;
        }

        const error = hint?.originalException;
        if (!(error instanceof Error)) {
          return event;
        }

        // We can't do anything about them
        if (
          unwrapError(error, instancePredicate(UnauthenticatedError)) ||
          unwrapError(error, instancePredicate(NetworkError))
        ) {
          return null;
        }

        cleanupStackTrace(event);
        enhanceExceptionEvent(event, hint);

        event.extra = event.extra || {};

        event.extra["Error cause"] = extractErrorCause(error);

        const annaError = unwrapError(error, instancePredicate(AnnaErrorBase));
        if (annaError) {
          event.extra["Error extra"] = annaError.extra;
        }

        const httpRequestError = unwrapError(error, instancePredicate(HttpRequestError));
        if (httpRequestError) {
          event.tags = event.tags || {};
          event.tags["request.method"] = httpRequestError.diagnostics.request.method.toLowerCase();
          event.tags["request.baseUrl"] = httpRequestError.diagnostics.baseUrl;
          event.tags["request.url"] = httpRequestError.diagnostics.request.url;
          event.tags["request.httpCode"] = httpRequestError.diagnostics.httpCode;
          event.tags["request.apiCode"] = httpRequestError instanceof ApiError ? httpRequestError.code : undefined;

          event.extra["Request diagnostics"] = redactDiagnostics(httpRequestError.diagnostics);
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        const webticsHttpError = unwrapError(error, (e): e is ErrorTypes["http"] =>
          isWebticsError(e as unknown, "http"),
        );
        if (webticsHttpError) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          event.extra["Webtics response"] = webticsHttpError.responseText || "<empty>";
        }

        return event;
      },
    });
    SentryHost._initialised = true;
  }

  configure(userStore: UserStore): void {
    if (!SentryHost._initialised) {
      return;
    }
    reaction(
      () => userStore.user,
      (user) => {
        this._setUser(user);
      },
      { fireImmediately: true },
    );
  }

  private _setUser(user: UserData): void {
    Sentry.setUser({ id: user.alias });
  }
}

const errorConstructorRegex = /^(?:[A-Z]\w*)?Error/;

/**
 * Removing Error classes constructors from stack trace
 */
function cleanupStackTrace(event: Sentry.ErrorEvent): void {
  const error = event.exception?.values?.[0];
  if (!error) {
    return;
  }
  const frames = error.stacktrace?.frames;
  if (!frames) {
    return;
  }
  for (const frame of frames) {
    if (frame.function && errorConstructorRegex.test(frame.function)) {
      frame.in_app = false;
    }
  }
}

function extractErrorCause(error: Error): string {
  const result = [];
  let cause = error.cause;
  while (cause instanceof Error) {
    result.push(cause.toString());
    cause = cause.cause;
  }
  return result.join("\n\nCaused by:\n");
}

const errorFilters = [
  // Browser extensions
  { stack: "chrome-extension://" },
  { message: "chrome-extension://" },
  { stack: "rMainB" },
  { message: "exodus" },
  { message: "ethereum" },
  { message: "keplr" },
  { message: "tronWeb" },
  { message: "Magic Eden" },
  { message: "StreamMiddleware - Unknown response id" },
  { message: "ObjectMultiplex - malformed chunk" },
  { message: "Could not inject tron" },
  { message: "Running analytics_debug.js" },
  { message: "Object Not Found Matching" },
  { message: "There was an error setting cookie `_pk_" },
  { message: "registerMyClickListener" },
  { stack: "fetchAndApplyCosmeticRules" },
  { message: "window.webkit.messageHandlers" },
  { message: "feature named `clickToLoad`" },
  { message: "runtime.sendMessage()" },
  { message: "tabReply will be removed" },
  // Stripe
  { message: "Failed to load Stripe.js" },
  // Generic
  { message: /can't redefine.*userAgent/i },
  { message: "Not implemented on this platform" },
  { message: "Unexpected token '<', \"<html>" },
] as const;

function shouldIgnoreEvent(event: Sentry.ErrorEvent): boolean {
  const error = event.exception?.values?.[0];
  for (const filter of errorFilters) {
    if (
      "message" in filter &&
      (matchesFilter(event.message, filter.message) || matchesFilter(error?.value, filter.message))
    ) {
      return true;
    }
    if ("stack" in filter && error?.stacktrace?.frames?.some((x) => matchesFilter(x.filename, filter.stack))) {
      return true;
    }
  }
  return false;
}

function matchesFilter(value: Nullable<string>, filter: string | RegExp): boolean {
  if (!value) {
    return false;
  }
  if (typeof filter === "string") {
    return value.toLowerCase().includes(filter.toLowerCase());
  }
  if (filter instanceof RegExp) {
    return filter.test(value);
  }
  throw new UnexpectedValueError("filter", filter);
}

function redactDiagnostics(diagnostics: HttpRequestDiagnostics): HttpRequestDiagnostics {
  const headers = diagnostics.request.headers;
  const authorizationHeader = headers ? Object.keys(headers).find((x) => x.toLowerCase() === "authorization") : null;
  if (!authorizationHeader) {
    return diagnostics;
  }
  const redactedHeaders = { ...headers };
  redactedHeaders[authorizationHeader] = "<redacted>";
  return {
    ...diagnostics,
    request: {
      ...diagnostics.request,
      headers: redactedHeaders,
    },
  };
}
