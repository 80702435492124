import { CompanyProduct } from "services/companyProducts/companyProductsTypes";

export enum ParticularsQuestion {
  Particulars = "PARTICULARS",
  SharesManagement = "SHARES_MANAGEMENT",
}

export enum ParticularsEntity {
  Particulars = "Particulars",
  SharesManagement = "SharesManagement",
}

export const PARTICULARS_QUESTION_TO_PRODUCT: { [key in ParticularsQuestion]?: CompanyProduct } = {
  [ParticularsQuestion.SharesManagement]: CompanyProduct.SharesManagement,
};
