import { type CompanyStore } from "services/company/companyStore";
import type { CompanyBusinessActivitiesStore } from "services/companyBusinessActivities/gb/companyBusinessActivitiesStore";
import { IssueKey, type IssuesStore } from "services/issues/issuesStore";
import { type PricingPlanStore } from "services/pricingPlan/gb/pricingPlanStore";
import { QuestionsStore } from "services/questions/questionsStore";
import { CompanyQuestion, ISSUE_FIELD_TO_QUESTION, QUESTION_TO_PRODUCT } from "./companyConstants";
import type { CompanyAddressStore } from "services/companyAddress/gb/companyAddressStore";

export class CompanyQuestionsStore extends QuestionsStore<CompanyQuestion> {
  protected _issueKey = IssueKey.Company;
  protected _issueFieldsToQuestions = ISSUE_FIELD_TO_QUESTION;

  constructor(
    private readonly _companyStore: CompanyStore,
    private readonly _companyAddressStore: CompanyAddressStore,
    private readonly _companyBusinessActivitiesStore: CompanyBusinessActivitiesStore,
    private readonly _pricingPlanStore: PricingPlanStore,
    protected readonly _issuesStore: IssuesStore,
  ) {
    super(CompanyQuestion, _issuesStore);
  }

  protected _getRelevantQuestion(): CompanyQuestion | undefined {
    const { company } = this._companyStore;

    if (!this._companyAddressStore.address) {
      return CompanyQuestion.Address;
    }

    if (!company?.natureOfBusiness) {
      return CompanyQuestion.Description;
    }

    if (!this._companyBusinessActivitiesStore.hasActivities) {
      return CompanyQuestion.Activities;
    }

    if (!company.startsTrading) {
      return CompanyQuestion.Starting;
    }

    return undefined;
  }

  protected _shouldSkipQuestion(question: CompanyQuestion): boolean {
    const product = QUESTION_TO_PRODUCT[question];

    if (product && this._pricingPlanStore.isProductIncluded(product)) {
      return true;
    }

    return super._shouldSkipQuestion(question);
  }
}
