import { type ApiClient } from "@anna-money/anna-web-lib/api";

import { PaymentMethodResponseData, PaymentMethodSetupIntent } from "./stripeIntegrationTypes";

export class StripeIntegrationClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  async beginSetupPaymentMethod(): Promise<PaymentMethodSetupIntent> {
    const response = await this._apiClient.post("api/stripe-integration/payment-method-setup", {});

    return response.getData(PaymentMethodSetupIntent);
  }

  async getPaymentMethods(): Promise<PaymentMethodResponseData> {
    const response = await this._apiClient.get("api/stripe-integration/payment-methods");

    return response.getData(PaymentMethodResponseData);
  }
}
