import type { IssueFieldQuestionMap } from "services/issues/issuesStore";

export enum DirectorQuestion {
  Name = "DIRECTOR_NAME",
  Birthday = "DIRECTOR_BIRTHDAY",
  Nationality = "DIRECTOR_NATIONALITY",
  Address = "DIRECTOR_ADDRESS",
  PreviousAddress = "DIRECTOR_PREVIOUS_ADDRESS",
  Attributes = "DIRECTOR_ATTRIBUTES",
}

export const ISSUE_FIELD_TO_QUESTION: IssueFieldQuestionMap<DirectorQuestion> = {
  dob: DirectorQuestion.Birthday,
  first_name: DirectorQuestion.Name,
  middle_names: DirectorQuestion.Name,
  last_name: DirectorQuestion.Name,
  nationality: DirectorQuestion.Nationality,
  attributes: DirectorQuestion.Attributes,
  address: DirectorQuestion.Address,
  service_address: DirectorQuestion.Address,
};

export const QUESTION_TO_PATH = {
  [DirectorQuestion.Name]: "name",
  [DirectorQuestion.Birthday]: "birthday",
  [DirectorQuestion.Nationality]: "nationality",
  [DirectorQuestion.Address]: "address",
  [DirectorQuestion.PreviousAddress]: "previous-address",
  [DirectorQuestion.Attributes]: "attributes",
};
