import { makeObservable, observable, runInAction } from "mobx";
import { registerFormStepParam } from "auth/authPages";
import { FormSubmitMethod } from "helpers/decorators/formSubmitMethod";
import { getUrlParam } from "helpers/url";
import { type IAnalyticsManager } from "services/analytics/analyticsManager";
import { type IIncorporationStore } from "services/incorporation/incorporationStore";
import { IncorporationStatus } from "services/incorporation/incorporationTypes";
import { type IIssuesStore } from "services/issues/issuesStore";
import type { IUserStore } from "services/user/userStore";
import type { FlowType } from "services/user/userTypes";
import { type FormSubmitResult } from "types/form";
import { type LoadableState } from "types/loadableState";
import { type RegisterFormStepsStoreBase } from "./registerFormStepsStoreBase";

type RegisterFormStoreState = LoadableState<"done">;

export abstract class RegisterFormStoreBase<T> {
  protected abstract _flowType: FlowType;
  protected abstract _init(): Promise<void>;
  protected abstract _getRelevantStep(): T;
  protected abstract _updateStepData(data?: unknown, entity?: unknown): Promise<void>;

  private _state: RegisterFormStoreState = "loading";

  protected constructor(
    protected readonly _stepsStore: RegisterFormStepsStoreBase<T>,
    protected readonly _userStore: IUserStore,
    protected readonly _incorporationStore: IIncorporationStore,
    protected readonly _companyIssuesStore: IIssuesStore,
    protected readonly _analyticsManager: IAnalyticsManager,
  ) {
    makeObservable(this, {
      _state: observable,
    } as any);
  }

  protected set state(value: RegisterFormStoreState) {
    this._state = value;
  }

  get state(): RegisterFormStoreState {
    return this._state;
  }

  get showAlert(): boolean {
    return (
      this._incorporationStore.incorporation?.status === IncorporationStatus.Rejected &&
      this._companyIssuesStore.hasIssues() &&
      !this._stepsStore.isLastStep
    );
  }

  get hideBackButton(): boolean {
    return this._stepsStore.isFirstStep || Boolean(this._incorporationStore.incorporation);
  }

  protected getHideBackButton(): boolean {
    return this._stepsStore.isFirstStep || Boolean(this._incorporationStore.incorporation);
  }

  protected get isRejectedButAllIssuesResolved(): boolean {
    const { incorporation } = this._incorporationStore;
    return incorporation?.status === IncorporationStatus.Rejected && !this._companyIssuesStore.hasIssues();
  }

  async init(): Promise<void> {
    try {
      if (this._userStore.user.currentFlowType !== this._flowType) {
        await this._userStore.updateCurrentFlow(this._flowType);
      }

      await this._init();

      runInAction(() => {
        this._setStep();

        this.state = "done";
      });
    } catch (error: unknown) {
      this.state = "error";

      this._analyticsManager.event("load-form-error");

      throw new Error(`Form couldn‘t load with the error: ${(error as Error).toString()}`);
    }
  }

  goBack(): void {
    this._analyticsManager.event("click-back", {
      title: this._stepsStore.activeStep.title,
      type: this._stepsStore.activeStep.type,
      stepId: this._stepsStore.activeStepTestId,
    });
    this._stepsStore.goPreviousQuestion();
  }

  @FormSubmitMethod()
  async onSubmit(data?: unknown, entity?: unknown): Promise<FormSubmitResult> {
    await this._updateStepData(data, entity);
    this._analyticsManager.event("click-next", {
      title: this._stepsStore.activeStep.title,
      type: this._stepsStore.activeStep.type,
      stepId: this._stepsStore.activeStepTestId,
    });
    await this._stepsStore.goNextQuestion();
  }

  protected async _createIncorporation(): Promise<void> {
    await this._incorporationStore.createIncorporation();

    this._analyticsManager.googleEvent("application_sent");
  }

  private _setStep(): void {
    if (this._incorporationStore.incorporation) {
      this._stepsStore.setStep(
        this.isRejectedButAllIssuesResolved ? this._stepsStore.confirmStep : this._stepsStore.competeStep,
      );
      return;
    }

    this._stepsStore.setStep(this._tryGetStepFromUrl() || this._getRelevantStep());
  }

  private _tryGetStepFromUrl(): T | undefined {
    const stepFromUrl = getUrlParam(registerFormStepParam) ?? "";
    return this._stepsStore.getStepByName(stepFromUrl);
  }
}
